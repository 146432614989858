export const environment = {
  useMocks: false,
  useProdMode: true,
  enableConsoleLog: true,
  enableComponentGallery: false,
  apollo: {
    apiUrl: 'https://staging-portal.klobal.cz/graphql',
    subscriptionsUrl: 'wss://staging-portal.klobal.cz/subscriptions',
  },
  rest: {
    apiV2Url: "https://staging-portal.klobal.cz/api/"
  },
  sentry: {
    allow: true,
    dsn: 'https://e38b561a9d52741aa5f5ee3352b0da3e@o4506434096201728.ingest.us.sentry.io/4507922894159872',
    allowReplay: false
  },
  routerTracing: false,
  environment: 'staging',
  appVersion: 'stg-v2024.4.0'
};
